<template>
  <b-card
    @click="changePage(page, vitalSignBodyMass.length)"
  >
    <b-media vertical-align="center">
      <template #aside>
        <b-button
          class="btn-icon"
          :variant="icon[1]"
        >
          <feather-icon
            size="32"
            :icon="icon[0]"
          />
        </b-button>
      </template>
      <b-row>
        <b-col
          cols="7"
          sm="8"
        >
          <h6>
            {{ t(`medical.${page}`) }}
          </h6>
          <h6 v-if="vitalSignBodyMass.length === 0">
            <span class="mb-0 text-secondary"> No Data </span>
            <small>&nbsp;</small>
          </h6>
          <h3 v-else>
            <h3 v-if="page === 'temp'">
              {{ vitalSignBodyMass[0].temp }} <small><sup>o</sup>C</small>
            </h3>
            <h3 v-if="page === 'pulse'">
              {{ vitalSignBodyMass[0].pulse }} <small>bpm</small>
            </h3>
            <h3 v-if="page === 'bp'">
              {{ vitalSignBodyMass[0].systolic }}/{{ vitalSignBodyMass[0].diastolic }} <small>mm/Hg</small>
            </h3>
            <h3 v-else>
              {{ vitalSignBodyMass[0].bmi }}
            </h3>
            <div
              class="small"
            >
              {{ currentDateTimeDiff(vitalSignBodyMass[0].dateProvider.substring(0,10)) }}
            </div>
            <small>{{ $helpers.getFullDate(vitalSignBodyMass[0].dateProvider.substring(0,10), this.$i18n.locale) }}</small>
          </h3>
        </b-col>
        <b-col
          class="text-right align-self-center"
        >
          <feather-icon
            v-if="vitalSignBodyMass.length > 0"
            icon="ChevronRightIcon"
            size="50"
          />
        </b-col>
      </b-row>
    </b-media>
  </b-card>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import moment from 'moment'

export default {
  props: {
    vitalSignBodyMass: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: Array,
      default: () => [],
    },
    page: {
      type: String,
      default: () => '',
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
      patientInfo: JSON.parse(localStorage.getItem('patientData')),
    }
  },
  methods: {
    currentDateTimeDiff(LastDate) {
      const lastDateBodyMass = moment(LastDate)
      const currentDate = moment()
      const durationBodyMass = moment.duration(
        currentDate.diff(lastDateBodyMass),
      )
      const days = Math.round(durationBodyMass.asDays())
      const months = Math.round(durationBodyMass.asMonths())
      const years = Math.round(durationBodyMass.asYears())
      let amount = ''
      if (years >= 1) {
        amount = `${years} ${this.$t('medical.year')}`
      }
      if (days <= 30) {
        amount = `${days} ${this.$t('medical.day')}`
      }
      if (months < 12 && months !== 0) {
        amount = `${months} ${this.$t('medical.month')}`
      }
      return amount
    },
    changePage(pageType, countData) {
      if (countData > 0) {
        this.$router
          .push(`/medical-vital-sign-body-mass/${pageType}`)
          .catch(() => {})
      }
    },
  },
}
</script>

<style>

</style>
