<template>
  <div id="div-follow">
    <profile-view-as v-if="patientView && patientView.idCard !== patientInfo.idCard" />
    <h4>
      {{ t("title.Vital Sign") }}
    </h4>
    <small>
      {{ t("title.Vital Sign History") }}
    </small>
    <b-overlay :show="vitalSignBodyMass.length === 0">
      <b-row
        class="mt-1"
      >
        <!-- Temp -->
        <b-col
          md="6"
          xl="4"
        >
          <profile-vital-sign-body-mass-card
            :page="'temp'"
            :vital-sign-body-mass="vitalSignBodyMass.tempInfos"
            :icon="iconVitalSign('temp')"
          />
        </b-col>
        <!-- Pulse -->
        <b-col
          md="6"
          xl="4"
        >
          <profile-vital-sign-body-mass-card
            :page="'pulse'"
            :vital-sign-body-mass="vitalSignBodyMass.pulseInfos"
            :icon="iconVitalSign('pulse')"
          />
        </b-col>
        <!-- Systolic -->
        <b-col
          md="6"
          xl="4"
        >
          <profile-vital-sign-body-mass-card
            :page="'bp'"
            :vital-sign-body-mass="bpInfos"
            :icon="iconVitalSign('bp')"
          />
        </b-col>
        <!-- BMI -->
        <b-col
          md="6"
          xl="4"
        >
          <profile-vital-sign-body-mass-card
            :page="'bmi'"
            :vital-sign-body-mass="vitalSignBodyMass.bodyMassInfos"
            :icon="iconVitalSign('bmi')"
          />
        </b-col>
      </b-row>
    </b-overlay>

  </div>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import ProfileVitalSignBodyMassCard from './ProfileVitalSignBodyMassCard.vue'
import ProfileViewAs from './ProfileViewAs.vue'

export const sortCompare = key => (a, b) => {
  const fieldA = a[key]
  const fieldB = b[key]

  let comparison = 0
  if (fieldA > fieldB) {
    comparison = -1
  } else if (fieldA < fieldB) {
    comparison = 1
  }
  return comparison
}

export default {
  components: {
    ProfileVitalSignBodyMassCard,
    ProfileViewAs,
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
      patientInfo: JSON.parse(localStorage.getItem('patientData')),
    }
  },
  data() {
    return {
      patientView: JSON.parse(localStorage.getItem('patientTempData')),
      date: null,
      vitalSignBodyMass: [],
      bpInfos: [],
      vitalSign: {
        temp: '36.5',
        tempDate: '10/01/2564',
        pulse: '112',
        pulseDate: '10/01/2564',
        bp: '120/80',
        bpDate: '10/01/2564',
        bmi: '20',
        bmiDate: '10/01/2564',
      },
      amountLastBMDate: null,
    }
  },
  async created() {
    // this.loadVitalSign()
    await this.loadVitalSignBodyMass()
    // const bodyMassLastDate = Date.parse(this.vitalSignBodyMass.bodyMassInfos[0].dateProvider.substring(0, 10))
    // this.amountLastBMDate = Math.ceil(Math.abs(currentDate - bodyMassLastDate))
  },
  methods: {
    changeRoute() {
      this.$router.push({ name: 'member-appointment' })
    },
    loadVitalSignBodyMass() {
      this.vitalSignBodyMass = []
      this.$http({
        url: `http://phrapi.sriphat.com/api/Customer_Health_Record_/VitalSignAndBodyMass/${this.patientInfo.hn}`,
        // url: 'http://phrapi.sriphat.com/api/Customer_Health_Record_/VitalSignAndBodyMass/0227187',
        method: 'GET',
        data: {},
      })
        .then(({ data }) => {
          this.vitalSignBodyMass = data
          // for (let i = 0; i < this.vitalSignBodyMass.diastolicInfos.length; i += 1) {
          //   this.bpInfos.push({
          //     diastolic: this.vitalSignBodyMass.diastolicInfos[i].diastolic,
          //     systolic: this.vitalSignBodyMass.systolicInfos[i].systolic,
          //     dateProvider: this.vitalSignBodyMass.systolicInfos[i].dateProvider,
          //   })
          // }
          // eslint-disable-next-line no-nested-ternary
          // this.vitalSignBodyMass.bodyMassInfos.sort((a, b) => ((a.dateProvider < b.dateProvider) ? 1 : ((b.dateProvider < a.dateProvider) ? -1 : 0)))

          // Sort Data
          const tempTempInfos = data.tempInfos
          this.vitalSignBodyMass.tempInfos = tempTempInfos.sort(sortCompare('dateProvider'))
          const tempPulseInfos = data.pulseInfos
          this.vitalSignBodyMass.pulseInfos = tempPulseInfos.sort(sortCompare('dateProvider'))
          const tempDiastolicInfos = data.diastolicInfos
          this.vitalSignBodyMass.diastolicInfos = tempDiastolicInfos.sort(sortCompare('dateProvider'))
          const tempSystolicInfos = data.systolicInfos
          this.vitalSignBodyMass.systolicInfos = tempSystolicInfos.sort(sortCompare('dateProvider'))
          const tempBodyMassInfos = data.bodyMassInfos
          this.vitalSignBodyMass.bodyMassInfos = tempBodyMassInfos.sort(sortCompare('dateProvider'))

          for (let i = 0; i < this.vitalSignBodyMass.diastolicInfos.length; i += 1) {
            this.bpInfos.push({
              diastolic: this.vitalSignBodyMass.diastolicInfos[i].diastolic,
              systolic: this.vitalSignBodyMass.systolicInfos[i].systolic,
              dateProvider: this.vitalSignBodyMass.systolicInfos[i].dateProvider,
            })
          }
          // this.loadingVitalBodyMass = false

        })
        .catch(error => {
          console.log('loadVitalSignBodyMass', error)
        })
    },
    changePage(pageType, countData) {
      console.log(pageType, countData)
      if (countData > 0) {
        this.$router
          .push(`/medical-vital-sign-body-mass/${pageType}`)
          .catch(() => {})
      }
    },
    // eslint-disable-next-line consistent-return
    iconVitalSign(value) {
      if (value === 'temp') return ['ThermometerIcon', 'warning']
      if (value === 'pulse') return ['HeartIcon', 'success']
      if (value === 'bp') return ['DropletIcon', 'danger']
      if (value === 'bmi') return ['UserIcon', 'info']
    },

  },
}
</script>
